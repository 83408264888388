var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Link } from 'nuri';
import Styles from '../../less/table-period.less';
import AddRecordDialog from './AddRecordDialog';
import { trackEvent } from '../Tracking';
import * as util from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TableItem_CreateRecordDocument } from './__generated__/TableItem.graphql';
var WEEKDAYS = ['일', '월', '화', '수', '목', '금', '토'];
var DATE_FORMAT = null;
try {
    DATE_FORMAT = new Intl.DateTimeFormat('ko', {
        month: '2-digit', day: '2-digit', weekday: 'short',
        timeZone: 'Asia/Seoul'
    });
}
catch (e) { }
function getDate(value) {
    if (DATE_FORMAT) {
        return DATE_FORMAT.format(value).replace(/([0-9]+)\. ([0-9]+)\./, '$1/$2');
    }
    var weekday = WEEKDAYS[value.getDay()];
    return (util.zerofill(value.getMonth() + 1) +
        '/' +
        util.zerofill(value.getDate()) +
        ' (' +
        weekday +
        ')');
}
var StatusButton = /** @class */ (function (_super) {
    __extends(StatusButton, _super);
    function StatusButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showAddModal: false,
        };
        _this._showAddModal = function (event) {
            event.preventDefault();
            _this.setState({ showAddModal: true });
        };
        _this._closeAddModal = function () {
            _this.setState({ showAddModal: false });
        };
        _this._recordAdded = function (result) {
            _this.setState({ showAddModal: false });
            trackEvent({
                eventCategory: 'Record',
                eventAction: 'Create',
                eventLabel: 'Table',
            });
            _this.props.onAddRecord(_this.props.item, result.record);
        };
        return _this;
    }
    StatusButton.prototype.render = function () {
        var record = this.props.item.record;
        if (record) {
            return (React.createElement(Link, { className: Styles.favoriteButtonActive, to: "/records/".concat(record.databaseId, "/") },
                React.createElement(FontAwesomeIcon, { icon: faPencil }),
                record.statusType && util.GQL_STATUS_TYPE_TEXT[record.statusType],
                record.status && (React.createElement("span", { className: Styles.favoriteButtonSubtext },
                    "@ ",
                    util.getStatusDisplayGql(record)))));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(Link, { className: Styles.favoriteButtonNormal, to: '/records/add/' + encodeURIComponent(this.props.item.title) + '/', onClick: this._showAddModal },
                    React.createElement(FontAwesomeIcon, { icon: faPlus }),
                    "\uC791\uD488 \uCD94\uAC00"),
                this.state.showAddModal && (React.createElement(AddRecordDialog, { initialStatusType: "INTERESTED" /* StatusType.Interested */, initialTitle: this.props.item.title, onCancel: this._closeAddModal, onCreate: this._recordAdded, createRecordMutation: TableItem_CreateRecordDocument }))));
        }
    };
    return StatusButton;
}(React.Component));
function Poster(_a) {
    var work = _a.work;
    return (React.createElement("div", { className: Styles.poster },
        React.createElement("img", { src: work.imageUrl, className: Styles.posterImage }),
        React.createElement("div", { className: Styles.posterOverlay },
            React.createElement(FontAwesomeIcon, { icon: faCheck }),
            " ",
            work.recordCount)));
}
var creditTypeText = {
    'ORIGINAL_WORK': '원작',
    'CHIEF_DIRECTOR': '총감독',
    'SERIES_DIRECTOR': '시리즈 감독',
    'DIRECTOR': '감독',
    'SERIES_COMPOSITION': '시리즈 구성',
    'CHARACTER_DESIGN': '캐릭터 디자인',
    'MUSIC': '음악',
};
export function TableItem(_a) {
    var item = _a.item, onAddRecord = _a.onAddRecord;
    var work = item.work;
    var _b = work.metadata, studioNames = _b.studioNames, source = _b.source, schedules = _b.schedules, durationMinutes = _b.durationMinutes, websiteUrl = _b.websiteUrl, namuwikiUrl = _b.namuwikiUrl, translatedJaWikipediaUrl = _b.translatedJaWikipediaUrl, annUrl = _b.annUrl;
    return (React.createElement("div", { className: Styles.item },
        React.createElement(Link, { to: util.getWorkURL(work.title) },
            React.createElement(Poster, { work: work })),
        React.createElement("div", { className: Styles.itemContent },
            React.createElement("h3", { className: Styles.title },
                item.title,
                durationMinutes && React.createElement("span", { className: Styles.duration },
                    durationMinutes,
                    "\uBD84")),
            React.createElement("div", { className: Styles.info },
                source && React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "source" }, util.SOURCE_TYPE_MAP[source]),
                    ' / '),
                React.createElement("span", { className: "studio" }, studioNames ? "".concat(studioNames.join(', '), " \uC81C\uC791") : '제작사 미정')),
            React.createElement("div", { className: Styles.actions },
                React.createElement(StatusButton, { item: item, onAddRecord: onAddRecord })),
            schedules && (React.createElement("div", { className: Styles.schedules },
                renderSchedule('jp', schedules),
                renderSchedule('kr', schedules))),
            React.createElement("div", { className: Styles.credits }, item.recommendations && item.recommendations.length > 0 && (item.recommendations.map(function (_a) {
                var credit = _a.credit, related = _a.related;
                return credit && (React.createElement("div", { className: Styles.credit },
                    React.createElement("span", { className: Styles.creditType }, creditTypeText[credit.type]),
                    credit.name,
                    ' ',
                    React.createElement("span", { className: Styles.creditRelated },
                        "(",
                        related && related.map(function (it) { return it.workTitle; }).join(', '),
                        ")")));
            }))),
            React.createElement("div", { className: Styles.links },
                websiteUrl && (React.createElement("a", { href: websiteUrl, className: "link link-official", target: "_blank" }, "\uACF5\uC2DD \uC0AC\uC774\uD2B8")),
                namuwikiUrl && (React.createElement("a", { href: namuwikiUrl, className: "link link-namu", target: "_blank" }, "\uB098\uBB34\uC704\uD0A4")),
                translatedJaWikipediaUrl && (React.createElement("a", { href: translatedJaWikipediaUrl, className: "link link-wikipedia", target: "_blank" }, "\uC704\uD0A4\uBC31\uACFC (\u65E5)")),
                annUrl && (React.createElement("a", { href: annUrl, className: "link link-ann", target: "_blank" }, "ANN (en)")))),
        React.createElement("div", { style: { clear: 'left' } })));
}
function renderSchedule(country, schedules) {
    var schedule = schedules.find(function (it) { return it.country === country; });
    if (!schedule)
        return null;
    var date = schedule.date, datePrecision = schedule.datePrecision, broadcasts = schedule.broadcasts;
    var dateObject = date ? new Date(date) : null;
    return (React.createElement("div", { className: Styles.schedule + ' item-schedule-' + country },
        dateObject ? (React.createElement("span", { className: "date" }, getDate(dateObject))) : (React.createElement("span", { className: "date" }, "\uBBF8\uC815")),
        dateObject && datePrecision === 'DATE_TIME' && (React.createElement("span", { className: "time" },
            " ",
            util.formatTime(dateObject))),
        broadcasts && [
            ' ',
            React.createElement("span", { className: "broadcasts" },
                "(",
                broadcasts.join(', '),
                ")"),
        ]));
}
